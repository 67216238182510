import React, {useState} from "react";
import {
    Box,
    Theme,
    Button,
    Typography,
} from "@mui/material";
import{
  createStyles,
  makeStyles,
  withStyles,
} from "@mui/styles";
import {Room, BookedSlot, Customer} from "../../models/models";
import {RoomCalendarScreen} from "./RoomCalendarScreen";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
    container: {
      flex: 1,
      display: "flex",
      flexDirection: "column",
      marginTop: 10,
      marginBottom: 10,
      marginLeft: 10,
      marginRight: 10,
      borderRadius: 10,
      overflow: "auto",
      width: "100%",
    },
    })
);

const CustomButton = withStyles({
  root: {
    '&:hover':{
        opacity: 0.7,
    },
    '&:active':{
        opacity: 0.7,
    },
//    '&:focus':{
//        opacity: 0.7,
//    }
  },
})(Button);


export const RoomScreen: React.FC<{
  customer: Customer,
  room: Room,
  bookedSlots: BookedSlot[],
  saveRoomBooking: (bookedSlots: BookedSlot[]) => void,
  deleteRoomBooking: (bookedSlot: BookedSlot) => void,
}> = ({
  customer,
  room,
  bookedSlots,
  saveRoomBooking,
  deleteRoomBooking
}) => {

  const classes = useStyles();

  const [selectedRoom, setSelectedRoom] = useState<Room>(null);

  const openRoom = (room: Room) => {
    setSelectedRoom(room);
  }

  const closeRoom = () => {
    setSelectedRoom(null);
  }


  return (

       <Box className={classes.container}>
         <CustomButton
           variant="contained"
           fullWidth
            style={{
              backgroundImage: `url('${room.backgroundImgUrl}')`,
              backgroundSize: 'cover',
              backgroundPosition: 'left 20%'
            }}
           onClick={() => openRoom(room)}
         >
         <Typography
           variant="h6"
           color="textPrimary"
           style={{
             marginTop: 20,
             marginBottom: 20 }}
         >
           This Room: {room.name}
         </Typography>
         </CustomButton>

         {selectedRoom &&
           <RoomCalendarScreen
              customer={customer}
              room={room}
              bookedSlots={bookedSlots}
              closeDialog={closeRoom}
              saveRoomBooking={saveRoomBooking}
              deleteRoomBooking={deleteRoomBooking}
           />
         }

       </Box>

);
};
