import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {AppThunk, RootState} from "../../app/store";
import {RoomFactory,
        Customer,
        CustomerFactory,
        Room} from "../../models/models";
import Config from "../../config";

interface RoomState {
  isFetching: boolean;
  rooms: Room[];
  error: null | string;
}

const initialState: RoomState = {
  isFetching: false,
  rooms: [],
  error: null
};

export const roomSlice = createSlice({
  name: "rooms",
  initialState,
  reducers: {
    storeFetching: (state, action: PayloadAction<boolean>) => {
      state.isFetching = action.payload;
    },
    storeRooms: (state, action: PayloadAction<Room[]>) => {
      state.rooms = action.payload.map((room) => room);
    },
    storeError: (state, action: PayloadAction<string | null>) => {
      state.error = action.payload;
    },
    purgeStore: (state) => {
      state.rooms = initialState.rooms;
      state.isFetching = false;
      state.error = null;
    },
  },
});

export const { storeRooms, storeFetching, storeError, purgeStore } = roomSlice.actions;


export const retrieveRooms =
  (authToken: string,
   customer: Customer): AppThunk =>
  async (dispatch) => {

    try {
      const headers: Headers = new Headers();

      headers.append("Accept", "application/json");
      headers.append("Content-Type", "application/json");
      headers.append("Authorization", `Bearer ${authToken}`);

      let url = `${Config.getInstance().getCoordinationServiceURL()}/api/retrieve_rooms`;

      dispatch(purgeStore());
      dispatch(storeFetching(true));

      const response = await fetch(url, {
        method: "POST",
        headers: headers,
        body: JSON.stringify(new CustomerFactory().toJSON(customer))
      })

      if (!response.ok) {
        dispatch(storeError("Error when fetching rooms"));
        dispatch(storeFetching(false));
        return;
      } else {
        dispatch(storeError(null));
      }

      const data = await response.json();

      if (!data || !Array.isArray(data)) {
        dispatch(storeError("Error when fetching rooms"));
        dispatch(storeFetching(false));
        return;
      }

      dispatch(
          storeRooms(data.map((roomJSON) =>
              new RoomFactory().fromJSON(roomJSON)
            )
          )
        );
      dispatch(storeFetching(false));

    } catch (error) {
      dispatch(storeError(error));
      dispatch(storeFetching(false));
      console.error(error);
    }
  };

export const selectIsFetching = (state: RootState) =>
  state.roomStore.isFetching;

export const selectError = (state: RootState) =>
  state.roomStore.error;

export const selectRooms = (state: RootState) =>{
    return state.roomStore.rooms;
}

export default roomSlice.reducer;
