import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {AppThunk, RootState} from "../../app/store";
import Config from "../../config";
import {Customer, CustomerFactory} from "../../models/models"

interface CustomerState {
  isFetching: boolean;
  authenticationToken: null | string;
  customer: null | Customer;
  error: null | string;
}

const initialState: CustomerState = {
  isFetching: false,
  authenticationToken: null,
  customer: null,
  error: null,
};

export const authenticationSlice = createSlice({
  name: "authentication",
  initialState,
  reducers: {
    storeFetching: (state, action: PayloadAction<boolean>) => {
      state.isFetching = action.payload;
    },
    storeAuthenticationToken: (state, action: PayloadAction<string | null>) => {
      state.authenticationToken = action.payload;
    },
    storeCustomer: (state, action: PayloadAction<Customer | null>) => {
      state.customer = action.payload;
    },
    storeError: (state, action: PayloadAction<string | null>) => {
      state.error = action.payload;
    },
    purgeStore: (state) => {
      state.isFetching = false;
      state.authenticationToken = null;
      state.customer = null;
      state.error = null;
    },
  },
});

export const {
  storeFetching,
  storeAuthenticationToken,
  storeCustomer,
  storeError,
  purgeStore,
} = authenticationSlice.actions;

/**
 * Retrieve an authentication token from the application server and store it into the authentication slice
 * @param username The username to authenticate with
 * @param password The password corresponding to given user
 */
export const authenticate =
  ({
    username,
    password,
  }: {
    username?: string;
    password?: string;
  }): AppThunk =>
  async (dispatch) => {
    try {
      const headers: Headers = new Headers();

      headers.append("Accept", "application/json");
      headers.append("Content-Type", "application/json");

      let url = `${Config.getInstance().getCoordinationServiceURL()}/api/login/`;

      dispatch(storeFetching(true));

      const response = await fetch(url, {
        method: "POST",
        headers: headers,
        body: JSON.stringify({
          username: username,
          password: password,
        }),
      });


      if (!response.ok) {
        dispatch(storeError("Incorrect username or password"));
      } else {
        dispatch(storeError(null));
      }

      const data = await response.json();

      const customerFactory = new CustomerFactory();

      dispatch(storeAuthenticationToken(data["access_token"]));
      dispatch(storeCustomer(customerFactory.fromJSON(data["customer"])));
      dispatch(storeFetching(false));

    } catch (error) {

      dispatch(storeFetching(false));
      console.error(error);
    }
  };

/**
 * Retrieve whether the authentication slice is currently syncing with the application server
 * @param state The redux root state
 */
export const selectIsFetching = (state: RootState) =>
  state.authenticationStore.isFetching;

/**
 * Retrieve the current authentication token from the authentication slice. The token is either a string
 * if the application is authenticated or null if it is not.
 * @param state The redux root state
 */
export const selectAuthenticationToken = (state: RootState) =>
  state.authenticationStore.authenticationToken;

/**
 * Retrieve the current authentication token from the authentication slice. The token is either a string
 * if the application is authenticated or null if it is not.
 * @param state The redux root state
 */
export const selectCustomer = (state: RootState) =>
  state.authenticationStore.customer;

/**
 * Retrieve the current error from the authentication slice. The error is either a string
 * if an error is present or null.
 * @param state The redux root state
 */
export const selectError = (state: RootState) =>
  state.authenticationStore.error;

export default authenticationSlice.reducer;
