import React, {useState} from "react";

import {authenticate, selectError, selectIsFetching} from "./authenticationSlice";
import {
    Box,
    Button,
    IconButton,
    InputAdornment,
    LinearProgress,
    TextField,
    Theme,
    Typography,
} from "@mui/material";
import {
    createStyles,
    makeStyles,
} from "@mui/styles";
import VisibilityRoundedIcon from "@mui/icons-material/VisibilityRounded";
import VisibilityOffRoundedIcon from "@mui/icons-material/VisibilityOffRounded";
import {FormattedMessage} from "react-intl";
import Logo from "../../resources/logo.svg";
import {useDispatch, useSelector} from "react-redux";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      zIndex: 20000,
      flex: 1,
      display: "flex",
      flexDirection: "column",
      overflow: "auto",
      paddingBottom: 20,
      position: "fixed",
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      padding: 20,
      alignItems: "center",
      justifyContent: "center",
      backgroundColor: theme.palette.background.default,
    },
    card: {
      backgroundColor: theme.palette.background.paper,
      borderRadius: 10,
      overflow: "hidden",
    },
    row: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "center",
      marginBottom: 10,
      maginTop: 10,
    },
    cardContainer: {
      display: "flex",
      flexDirection: "column",
      marginLeft: 20,
      marginRight: 20,
      marginTop: 20,
      marginBottom: 20
    },
    textField: {
      borderRadius: 10,
      backgroundColor: theme.palette.background.default,
    },
    logo: {
      height: 20,
      marginBottom: 40,
    },
    logoContainer: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
    },
    text: {
      marginBottom: 20,
    },
    error: {
      backgroundColor: theme.palette.secondary.main,
      padding: 10,
      borderRadius: 10,
      marginBottom: 10,
    },
  })
);


export function Authentication() {
  const dispatch = useDispatch();


  const classes = useStyles();

  const [password, setPassword] = useState<string>("");
  const [username, setUsername] = useState<string>("");

  const [showPassword, setShowPassword] = useState<boolean>(false);

    /**
   *
   * @param event
   */
  const onLoginButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (password) {
        // participant login
        dispatch(
          authenticate({
            username: username,
            password: password,
          })
        );
      }
    };

  const isFetching = useSelector(selectIsFetching);
  const error = useSelector(selectError);

  return (
    <Box className={classes.container}>
      <Box className={classes.card}>
        {isFetching && <LinearProgress color="primary" />}
        <Box className={classes.cardContainer}>
          <Box className={classes.logoContainer}>
            <img alt="logo" className={classes.logo} src={Logo} />
          </Box>
          <Box className={classes.row}>
             <Typography
               color="textSecondary"
               align="center"
               className={classes.text}
             >
               Please provide your username and password
             </Typography>
          </Box>
          {error && (
            <Box className={classes.error}>
              <Typography color="textPrimary">{error}</Typography>
            </Box>
          )}
          <Box className={classes.row}>
             <TextField
               size="small"
               label="Username"
               variant="filled"
               fullWidth
               type={"text"}
               InputProps={{
                 disableUnderline: true,
               }}
               className={classes.textField}
               value={username}
               onChange={(event) => setUsername(event.target.value)}
             />
          </Box>
          <Box className={classes.row}>
             <TextField
               size="small"
               label="Password"
               variant="filled"
               fullWidth
               type={showPassword ? "text" : "password"}
               InputProps={{
                 disableUnderline: true,
                 endAdornment: (
                     <InputAdornment position="end">
                         <IconButton color="lightColour" size="small" onClick={() => setShowPassword(!showPassword)}>
                             {showPassword &&
                                 <VisibilityRoundedIcon/>
                             }
                             {!showPassword &&
                                 <VisibilityOffRoundedIcon/>
                             }
                         </IconButton>
                     </InputAdornment>
                 ),
               }}
               className={classes.textField}
               value={password}
               onChange={(event) => setPassword(event.target.value)}
             />
          </Box>
          <Button
            color="primary"
            size="small"
            variant="contained"
            onClick={onLoginButtonClick}
          >
            <FormattedMessage id="login" defaultMessage="Login" />
          </Button>
        </Box>
      </Box>
    </Box>
  );
}
