import {Action, combineReducers, configureStore, ThunkAction,} from "@reduxjs/toolkit";
import {persistReducer, persistStore} from "redux-persist";
import localForage from "localforage";

import authenticationReducer from "../screens/authentication/authenticationSlice";
import roomReducer from "../screens/rooms/roomSlice";
import bookedSlotReducer from "../screens/rooms/bookedSlotSlice";


const persistConfig = {
  key: "store",
  storage: localForage,
  version: 1,
  // migrate: createMigrate(migrations, { debug: false }),
  //blacklist: ["notificationStore"],
  whitelist: [],
};

const rootReducer = combineReducers({
  authenticationStore: authenticationReducer,
  roomStore: roomReducer,
  bookedSlotStore: bookedSlotReducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
      immutableCheck: false,
    }),
});

const persistor = persistStore(store);

export { store, persistor };

export type RootState = ReturnType<typeof store.getState>;

export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
