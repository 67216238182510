import React, {useEffect, useState} from "react";
import {
    Box,
    Button,
    Dialog,
    IconButton,
    Slide,
    Theme,
    Typography,
    TextField,
    Divider
} from "@mui/material";
import {
    createStyles,
    makeStyles,
} from "@mui/styles";
import {formatDate} from "../../utils/dateFunctions";
import {formatTime} from "../../utils/dateFunctions";
import {BookedSlot} from "../../models/models";
import {v4 as uuid} from "uuid";
import { CloseRounded } from "@mui/icons-material";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
    container: {
      flex: 1,
      display: "flex",
      flexDirection: "column",
      marginLeft: 10,
      marginRight: 10,
      overflow: "auto",
    },
    formContainer: {
      display: "flex",
      flexDirection: "column",
      alignItems: "left",
      marginLeft: 10,
      marginRight: 10,
    },
    textField: {
      borderRadius: 10,
      backgroundColor: theme.palette.background.default,
    },
    informationContainer: {
      marginLeft: 10,
      marginRight: 10,
      marginBottom: 10,
      marginTop: 10,
    },
    row: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-between",
    },
    })
);


export const RemoveRoomBookingDialog: React.FC<{
  date: Date,
  customer: Customer,
  room: Room,
  selectedIndex: number,
  bookingSlots: BookingSlots[],
  openDialog: Boolean,
  closeDialog: () => void,  //send it back to parent on close
  deleteRoomBooking: (bookedSlot: BookedSlot) => void,

}> = ({
  date,
  customer,
  room,
  selectedIndex,
  bookingSlots,
  openDialog,
  closeDialog,
  deleteRoomBooking
}) => {

  const classes = useStyles();

  const initDeletedSlot = (bookingSlots: BookingSlots[]) => {
    //summarize all slots that need to be deleted

    const bookedMeetingId = bookingSlots[selectedIndex]?.bookedSlot?.id || null;

    const deletedBookingSlots = bookingSlots.filter((thisBookingSlot) => thisBookingSlot.bookedSlot?.id === bookedMeetingId)

    if(deletedBookingSlots.length > 0) {
      const deletedSlot = deletedBookingSlots[0].bookedSlot;
      return deletedSlot
    } else {
      return null
    }
  }

  useEffect(() => {
    setDeletedSlot(initDeletedSlot(bookingSlots));
  }, [bookingSlots, selectedIndex]);


  const [deletedSlot, setDeletedSlot] = useState<BookedSlot>();

  const currentCosts = (deletedSlot?.tend-deletedSlot?.tstart)*room.costPerHour/1000./3600. || 0.;

  return (

    <Dialog
          open={openDialog}
          onClose={closeDialog}
          fullWidth={true}
          maxWidth={"lg"}
          TransitionComponent={Slide}
          TransitionProps={{direction: "up"} as any}
    >
   {deletedSlot &&

    <Box className={classes.container}>
      <Box className={classes.row}>
        <Typography
          variant="h6"
          color="textPrimary"
          style={{ marginTop: 20, marginBottom: 20 }}
        >
          Remove Meeting: {deletedSlot.subject}
        </Typography>

        <IconButton
            edge="start"
            color="inherit"
            aria-label="close"
            onClick={closeDialog}
        >
           <CloseRounded />
        </IconButton>
      </Box>

      <Box style={{ height: 40 }} />

       <Divider/>


        <Box className={classes.informationContainer}>
            <Typography
              color="textPrimary"
              align="left"
              style={{ fontWeight: "bold" }}
            >
              Information
            </Typography>
            <Typography color="textSecondary" align="left">
               Customer: {customer.name}
            </Typography>
            <Typography color="textSecondary" align="left">
               Date: {formatDate(date)}
            </Typography>
            <Typography color="textSecondary" align="left">
               Room: {room.name}
            </Typography>
        </Box>

        <Box className={classes.informationContainer}>
            <Typography
              color="textPrimary"
              align="left"
              style={{ fontWeight: "bold" }}
            >
              Credit Balance
            </Typography>
            <Typography color="textSecondary" align="left">
               Available Credits: {customer.creditBalance}
            </Typography>
            <Typography color="textSecondary" align="left">
               Credits returned: {currentCosts}
            </Typography>
            <Typography color="textSecondary" align="left">
               Credits after transaction: {customer.creditBalance + currentCosts}
            </Typography>
        </Box>

     <Divider style={{marginBottom: 10}}/>

        <Box className={classes.informationContainer}>
            <Typography
              color="textPrimary"
              align="left"
              style={{ fontWeight: "bold" }}
            >
              Slots
            </Typography>
                <Typography
                 key={uuid()}
                 color="textSecondary" align="left"
                >
                   Time: {formatTime(deletedSlot.tstart)} - {formatTime(deletedSlot?.tend)}
            </Typography>
        </Box>

     <Divider style={{marginBottom: 10}}/>

     <Box className={classes.formContainer}>
          <Button
              variant="contained"
              color="error"
              fullWidth
              onClick={() => {
                deleteRoomBooking(deletedSlot);
                closeDialog();
              }
                }
            >
             Remove Meeting
            </Button>
        <Box style={{ height: 10,
                      marginBottom: 10,
                      marginTop: 0}} />
      </Box>



    </Box>
  }
      </Dialog>
);
};
