const DEFAULT_HOST = "http://localhost:5001";

const DEFAULT_SALESFORCE_HOST =
  "https://networkcircle--partialnc.lightning.force.com/";

// const DEFAULT_EVENT_ID = "a0M3Y00001FcEu7UAF";
// const DEFAULT_REGISTRATION_ID = "a0L3Y00000nO4qHUAS";

export default class Config {
  private static instance: Config;

  /**
   * Retrieve the shared config object
   * @return Config the shared config object
   */
  public static getInstance(): Config {
    if (!Config.instance) {
      Config.instance = new Config();
    }

    return Config.instance;
  }

  /**
   *  Retrieve the url of the salesforce server
   *  @return string the url of the salesforce server
   */
  getSalesforceServerURL(): string {
    return DEFAULT_SALESFORCE_HOST;
  }

  /**
   *  Retrieve the url of the coordination service
   *  @return string the url of the coordination service
   */
  getCoordinationServiceURL(): string {
    return process.env.REACT_APP_COORDINATION_SERVICE_URL || DEFAULT_HOST;
  }

  /**
   * Retrieve the current employee ID
   * @return string the current registration ID
   */
  getEmployeeId(): string {
    const urlSearchParams = new URLSearchParams(window.location.search);
    return urlSearchParams.get("employee_id") || "";
  }

  /**
   * Retrieve the current access token defined in url/qrcode
   * @return string the current access token
   */
  getAccessTokenQRCode(): string {
    const urlSearchParams = new URLSearchParams(window.location.search);
    return urlSearchParams.get("access_token") || "";
  }

}
