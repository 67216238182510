import {BookingSlot, Room} from "../../models/models";

export const getCurrentCosts = (bookingSlots: BookingSlot[], room: Room) => {
  return bookingSlots.filter(thisBookingSlot => thisBookingSlot.selected === true)
                     .reduce((sum, thisBookingSlot) => sum + 0.5*room.costPerHour, 0);
  }

export const getCurrentCostsB = (bookedSlots: BookedSlot[]) => {
   const currentCosts = bookedSlots.reduce((sum, thisBookedSlot) => {
                   const timeInterval = (thisBookedSlot.tend - thisBookedSlot.tstart)/3600./1000.
                   return sum + timeInterval * thisBookedSlot.room.costPerHour
                 }, 0);
  return currentCosts;
}
