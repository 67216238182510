
import {formatDate, formatDatetime} from '../utils/dateFunctions'

export interface BaseJSON {
  id: string;
}

export abstract class BaseModel {
  id: string;

  /**
   * Constructor of the Basemodel class
   * @param id database id of the object
   */
   /*note: since the same backend API is called also in event dashboard
   when updating a registration, we need to specify the origin where the request comes from
   */

  protected constructor(id: string) {
    this.id = id;
  }
}


export interface CustomerJSON extends BaseJSON {
  name: string;
  credit_balance: number;
}

export class Customer extends BaseModel {
  name: string;
  creditBalance: number;

  constructor(
    id: string,
    name: string,
    creditBalance: number,
  ) {
    super(id);

    this.name = name;
    this.creditBalance = creditBalance;
  }
}

export class CustomerFactory {
  /**
   *
   * @param json
   */
  fromJSON(json: CustomerJSON): Customer {
    return new Customer(
      json.id,
      json.name,
      json.credit_balance
    );
  }
  /**
   *
   * @param customer
   */
  toJSON(customer: Customer): CustomerJSON {
    return {
      id: customer.id,
      name: customer.name,
      credit_balance: customer.creditBalance
    };
  }
}

/******************************************************/

export interface RoomJSON extends BaseJSON {
  name: string;
  cost_per_hour: number;
  background_img_url: string;
}

export class Room extends BaseModel {
  name: string;
  costPerHour: number;
  backgroundImgUrl: string;

  constructor(
    id: string,
    name: string,
    costPerHour: number,
    backgroundImgUrl: string,
  ) {
    super(id);

    this.name = name;
    this.costPerHour = costPerHour;
    this.backgroundImgUrl = backgroundImgUrl;
  }
}

export class RoomFactory {
  /**
   *
   * @param json
   */
  fromJSON(json: RoomJSON): Room {
    return new Room(
      json.id,
      json.name,
      json.cost_per_hour,
      json.background_img_url
    );
  }
  /**
   *
   * @param room
   */
  toJSON(room: Room): RoomJSON {
    return {
      id: room.id,
      name: room.name,
      cost_per_hour: room.costPerHour,
      background_img_url: room.backgroundImgUrl
    };
  }
}

/******************************************************/

export interface BookedSlotJSON extends BaseJSON {
  subject: string;
  customer: CustomerJSON;
  room: RoomJSON;
  date: str;
  tstart: str;
  tend: str;
}

export class BookedSlot extends BaseModel {
  subject: string;
  customer: Customer;
  room: Room;
  date: Date;
  tstart: Date;
  tend: Date;

  constructor(
    id: string,
    subject: string,
    customer: Customer,
    room: Room,
    date: Date,
    tstart: Date,
    tend: Date
  ) {
    super(id);

    this.subject = subject;
    this.customer = customer;
    this.room = room;
    this.date = date;
    this.tstart = tstart;
    this.tend = tend;
  }
}

export class BookedSlotFactory {
  /**
   *
   * @param json
   */
  fromJSON(json: BookedSlotJSON): BookedSlot {
    const roomFactory = new RoomFactory();
    const customerFactory = new CustomerFactory();

    return new BookedSlot(
      json.id,
      json.subject,
      customerFactory.fromJSON(json.customer),
      roomFactory.fromJSON(json.room),
      new Date(json.tstart),
      new Date(json.tstart),
      new Date(json.tend)
    );
  }
  /**
   *
   * @param room
   */
  toJSON(bookedSlot: BookedSlot): BookedSlotJSON {
    const thisRoomFactory = new RoomFactory();
    const thisCustomerFactory = new CustomerFactory();
    return {
      id: bookedSlot.id,
      subject: bookedSlot.subject,
      customer: thisCustomerFactory.toJSON(bookedSlot.customer),
      room: thisRoomFactory.toJSON(bookedSlot.room),
      date: formatDate(bookedSlot.date),
      tstart: formatDatetime(bookedSlot.tstart),
      tend: formatDatetime(bookedSlot.tend)
    };
  }
}



/******************************************************/
//classes for booking slots (always half an hour duration)
//can be related to a booked slot from a customer
export interface BookingSlotJSON extends BaseJSON {
  booked_slot: BookedSlotJSON;
  tstart: str;
  tend: str;
  selected: boolean;
}

export class BookingSlot extends BaseModel {
  bookedSlot: BookedSlot;
  tstart: Date;
  tend: Date;
  selected: boolean;

  constructor(
    id: string,
    bookedSlot: BookedSlot,
    tstart: Date,
    tend: Date,
    selected: boolean,
  ) {
    super(id);

    this.bookedSlot = bookedSlot;
    this.tstart = tstart;
    this.tend = tend;
    this.selected = selected;
  }
}

export class BookingSlotFactory {
  /**
   *
   * @param json
   */
  fromJSON(json: BookingSlotJSON): BookingSlot {
    const bookedSlotFactory = new BookedSlotFactory();

    return new BookingSlot(
      json.id,
      bookedSlotFactory.fromJSON(json.booked_slot),
      new Date(json.tstart),
      new Date(json.tend),
      json.selected
    );
  }
  /**
   *
   * @param room
   */
  toJSON(bookingSlot: BookingSlot): BookingSlotJSON {
    const thisBookedSlotFactory = new BookedSlotFactory();
    return {
      id: bookingSlot.id,
      booked_slot: thisBookedSlotFactory.toJSON(bookingSlot.bookedSlot),
      tstart: formatDate(bookingSlot.tstart),
      tend: formatDate(bookingSlot.tend),
      selected: bookingSlot.selected
    };
  }
}
