import React, {useState, useRef, useEffect} from "react";
import {
    Button,
    Box,
    IconButton,
    Typography
} from "@mui/material";
import {
    createStyles,
    makeStyles,
} from "@mui/styles";
import {Room, BookingSlot, Customer} from "../../models/models";
import {SubmitRoomBookingDialog} from "./SubmitRoomBookingDialog";
import {RemoveRoomBookingDialog} from "./RemoveRoomBookingDialog";
import {formatTime} from "../../utils/dateFunctions";
import {getCurrentCosts} from "../../utils/costFunctions";
import {useSnackbar} from "notistack";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      backgroundColor: theme.palette.background.default,
    },
    bookingSlotContainer1: {
      flex: 1,
      display: "flex",
      flexDirection: "row",
      marginLeft: 10,
      marginRight: 10,
      justifyContent: "center",
      alignItems: "center",
      backgroundColor: theme.palette.background.paper,
      overflow: "auto",
      borderTop: '2px solid black',
      borderLeft: '2px solid black',
      borderRight: '2px solid black',
    },
    bookingSlotContainer2: {
      flex: 1,
      display: "flex",
      flexDirection: "row",
      marginLeft: 10,
      marginRight: 10,
      justifyContent: "center",
      alignItems: "center",
      backgroundColor: theme.palette.background.paper,
      overflow: "auto",
      borderTop: '2px dotted black',
      borderLeft: '2px solid black',
      borderRight: '2px solid black',
    },
    cancelContainer: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      width: '5%',
    },

    rowContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "left",
      backgroundColor: theme.palette.background.default,
      marginLeft: 10,
      marginRight: 10
    },
    buttonContainer: {
      flex: 1,
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-end",
    },
    timeContainer: {
      display: "flex",
      justifyContent: "left",
      alignItems: "center",
      width: '10%'
    },
    subjectContainer: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      width: '90%',
    }
  })
);



//Dialog screen for a certain think tank table
export const CalendarScreen: React.FC<{
  customer: Customer,
  room: Room,
  date: Date,
  bookingSlots: BookingSlots[],
  saveRoomBooking: (bookedSlots: BookedSlot[]) => void,
  deleteRoomBooking: (bookedSlot: BookedSlot) => void
}> = ({
  customer,
  room,
  date,
  bookingSlots,
  saveRoomBooking,
  deleteRoomBooking
}) => {

  const classes = useStyles();

  const { enqueueSnackbar } = useSnackbar();

  const scrollElement = useRef();
  const setScrollPosition = (element) => {
    element?.current.scrollIntoView({behavior: 'smooth'});
  };
  useEffect(() => {
    setScrollPosition(scrollElement);
  }, []);

//update available booking slots on date change
  useEffect(() => {
    setBookingSlotsTmp(bookingSlots);
  }, [bookingSlots]);

  //handle double clicks and single clicks
  const [selectedIndex, setSelectedIndex] = useState<integer>(null);
  const [clickCount, setClickCount] = useState<integer>(0);

  useEffect(() => {
    let timeoutId;
    if(clickCount === 1){
      //this is a single click
      timeoutId = setTimeout(() => {
        selectBookingSlot(selectedIndex);
        setClickCount(0);
      }, 100)
    } else if(clickCount === 2) {
      setClickCount(0);
      const openSubmitDialog = bookMeetingSlots(selectedIndex);
      setOpenSubmitDialog(openSubmitDialog);
    }
    return () => clearTimeout(timeoutId);
  }, [clickCount]);

  const handleClick = (event, index: integer) => {
    setSelectedIndex(index)
    setClickCount(clickCount + 1);
//    selectBookingSlot(index);
  }

  const selectBookingSlot = (index: integer) => {
    //select or de-select a booking slot at this index
    //calculate costs if this slot is booked additionally
    let currentCosts= getCurrentCosts(bookingSlotsTmp, room);
    if(bookingSlotsTmp[index].selected === false){
      currentCosts = currentCosts + 0.5*room.costPerHour
    }
    if(currentCosts > customer.creditBalance){
         enqueueSnackbar("Not enough credits.", {
               variant: "error",
          });
    } else {
    //set updated booking slots
      const updatedBookingSlots = [...bookingSlotsTmp];
      updatedBookingSlots[index].selected=!updatedBookingSlots[index].selected;
      setBookingSlotsTmp(updatedBookingSlots);
    }
  }

  const bookMeetingSlots = (index: integer) => {
    //select a booking slot and open dialog
    //calculate costs if this slot is booked additionally
    let currentCosts= getCurrentCosts(bookingSlotsTmp, room);
    if(bookingSlotsTmp[index].selected === false){
      currentCosts = currentCosts + 0.5*room.costPerHour
    }

    if(currentCosts > customer.creditBalance){
         enqueueSnackbar("Not enough credits.", {
               variant: "error",
          });
          return false;
    } else {
      const updatedBookingSlots = [...bookingSlotsTmp];
      updatedBookingSlots[index].selected=true;
      setBookingSlotsTmp(updatedBookingSlots);
      return true;
    }
  }

  const [bookingSlotsTmp, setBookingSlotsTmp] = useState<BookingSlot[]>(bookingSlots);
  const [openSubmitDialog, setOpenSubmitDialog] = useState<Boolean>(false);
  const [openRemoveDialog, setOpenRemoveDialog] = useState<Boolean>(false);
  const [removeIndex, setRemoveIndex] = useState<number>(null);

  const closeSubmitDialog = () => {
    setOpenSubmitDialog(false);
  }

  const closeRemoveDialog = () => {
    setOpenRemoveDialog(false);
    setRemoveIndex(null);
  }

  const datetimeNow = new Date();

  const getBackgroundColor = (thisBookingSlot) => {
    //the higher the number the lighter the colour
//    const grey15="#25262e"; //same as background.default
    const grey20="#2d3038"; //same as background.paper
    const grey30="#444955";
//    const grey50="#71798e";
//    const grey75="#b8bcc6";
    const blue40="#3a5d92";
    if(thisBookingSlot.selected === true){
      return blue40; //if slot is selected, blueish colour
    }
    if(thisBookingSlot.bookedSlot !== null){
      return grey20; //if slot already occupied, dark grey
    }
    if(thisBookingSlot.tstart <= datetimeNow){
      return grey20; //if slot in the past, dark grey
    }
    //default
    return grey30;
  }

  //reset datetimeNow every 5 seconds
//  const [countDown, setCountDown] = useState(new Date());
//  useEffect(() => {
//    const interval = setInterval(() => {
//    setCountDown(new Date());
//  }, 5000);
//    return () => clearInterval(interval);
//  }, [datetimeNow]);
//  console.log(datetimeNow);

  return (
    <Box className={classes.container}>
    <SubmitRoomBookingDialog
       date = {date}
       customer = {customer}
       room = {room}
       bookingSlots = {bookingSlotsTmp}
       openDialog = {openSubmitDialog}
       closeDialog = {closeSubmitDialog}
       saveRoomBooking = {saveRoomBooking}
    />


    <RemoveRoomBookingDialog
       date = {date}
       customer = {customer}
       room = {room}
       selectedIndex = {removeIndex}
       bookingSlots = {bookingSlotsTmp}
       openDialog = {openRemoveDialog}
       closeDialog = {closeRemoveDialog}
       deleteRoomBooking = {deleteRoomBooking}
    />

    <Box className={classes.rowContainer}>
       <Typography variant='h6'>
          Credit balance: {getCurrentCosts(bookingSlotsTmp, room)} / {customer.creditBalance}
       </Typography>

       <Box className={classes.buttonContainer}>
       <Button
          variant="contained"
          color="primary"
          disabled={!bookingSlotsTmp.some((thisBookingSlot) => thisBookingSlot.selected===true)}
          onClick={() => {
            setOpenSubmitDialog(true);
          }
            }
        >
         Submit
        </Button>
        </Box>
    </Box>

    <Box style={{ height: 10,
                  marginBottom: 10,
                  marginTop: 0}} />

    {bookingSlotsTmp.map((bookingSlot, index) => {
      const backgroundColor = getBackgroundColor(bookingSlot)
      return (
        <Box
           key={bookingSlot.id}
           className={bookingSlot.tstart.getMinutes()===0 ? classes.bookingSlotContainer1 : classes.bookingSlotContainer2}
           ref={formatTime(bookingSlot.tstart) === '08:00'? scrollElement : null}
           style={{backgroundColor: backgroundColor}}
        >
          <Button
           variant="outlined"
           disableRipple
           disabled={bookingSlot.bookedSlot!==null || bookingSlot.tstart <= datetimeNow }
           fullWidth
            style={{
              color: 'black',
              backgroundSize: 'cover',
              border: `0px solid black`,
              backgroundColor: backgroundColor,
            }}
            onClick={(e) => handleClick(e, index)}

           >
             <Box className={classes.timeContainer}>
                <Typography color='textSecondary'>
                  {formatTime(bookingSlot.tstart)}
                </Typography>
              </Box>

              <Box className={classes.subjectContainer}>
                 <Typography color='textSecondary'>
                    {bookingSlot.bookedSlot ?
                        bookingSlot.bookedSlot.customer.id === customer.id ?
                           bookingSlot.bookedSlot.customer.name + ' -- ' + bookingSlot.bookedSlot.subject
                       :
                       bookingSlot.bookedSlot.customer.name
                       :
                       ''
                    }
                  </Typography>
              </Box>
          </Button>
          {bookingSlot.bookedSlot?.customer?.id &&
           bookingSlot.bookedSlot?.customer?.id === customer?.id &&
           (datetimeNow - bookingSlot.bookedSlot?.tstart)/1000./3600. < -.25 &&
          <Box className={classes.cancelContainer}>
            <IconButton
                edge="start"
                color="inherit"
                style={{
                   backgroundColor: backgroundColor,
                }}
                onClick={() => {
                    setRemoveIndex(index);
                    setOpenRemoveDialog(true)
                  }
                }
              >
                <DeleteForeverIcon color="error"/>
              </IconButton>
          </Box>
        }
        </Box>
      )
    })}
    <Box style={{ height: 10,
                  marginBottom: 10,
                  marginTop: 0}} />
    </Box>
  );

};
