export const padTo2Digits = (num: number) => {
  return num.toString().padStart(2,'0');
}
export const padTo3Digits = (num: number) => {
  return num.toString().padStart(3,'0');
}

export const getLastSundayMar = ((currentYear) => {
    //get the utc time for the last sunday in  march at 1:00 UTC
    //indizes are running as follows:
    //Jan = 0; Feb = 1; March=2;
    //Sun=0; Mon=1; Tue=2; Wed=3; etc.
    for (let i=0; i<7; i++){
      let dateIndex = 31-i;
      const lastDateInMarch = new Date(Date.UTC(currentYear,2,dateIndex,1,0));
      if(lastDateInMarch.getUTCDay() === 0){
        return lastDateInMarch;
      }
    }
    //default (will never be required)
    return new Date(Date.UTC(currentYear,2,31,1));
  })

export const getLastSundayOct = ((currentYear) => {
    //get the utc time for the last sunday in  october at 1:00 UTC
    for (let i=0; i<7; i++){
      let dateIndex = 31-i;
      const lastDateInOctober= new Date(Date.UTC(currentYear,9,dateIndex,1,0));
      if(lastDateInOctober.getUTCDay() === 0){
        return lastDateInOctober;
      }
    }
    //default (will never be required)
    return new Date(Date.UTC(currentYear,9,31,1));
  })

export const getTimeZone = ((date: Date) => {
    //receive default zime zones:
    //CET or CEST (central european summer time)
      const currentYear= date.getUTCFullYear();
      const lastSundayMar = getLastSundayMar(currentYear);
      const lastSundayOct = getLastSundayOct(currentYear);
      if(lastSundayMar > date){
        return 'CET';
      }
      else if(lastSundayOct > date){
        return 'CEST';
      }
      else if(lastSundayOct <= date){
        return 'CET';
      }
      return 'UTC';
  });

export const formatDate = (date: Date) => {
  //return date in format of "YYYY-MM-DD"
  return (
    [date.getFullYear(),
    padTo2Digits(date.getMonth()+1),
    padTo2Digits(date.getDate())].join('-')
  )
}

export const formatTime = (date: Date) => {
  //return time in format of "HH:MM"
  return (
    [padTo2Digits(date.getHours()),
     padTo2Digits(date.getMinutes())].join(':')
  )
}

export const formatDatetime = (date: Date) => {
  //return date-time in format of "YYYY-MM-DDTHH:MM:SS.SSS+0000" (UTC time string)
  return (
    [date.getUTCFullYear(),'-',
     padTo2Digits(date.getUTCMonth()+1),'-',
     padTo2Digits(date.getUTCDate()),'T',
     padTo2Digits(date.getUTCHours()),':',
     padTo2Digits(date.getUTCMinutes()), ':',
     padTo2Digits(date.getUTCSeconds()), '.',
     padTo3Digits(date.getUTCMilliseconds()),
     '+0000'].join('')
  )
}

export const formatDuration = (duration: number) => {
  const seconds = duration%60;
  const minutes = ((duration-seconds)/60)%60;
  const hours = (duration-60*minutes-seconds)/60/60;
  //return time in hh:mm:ss for a duration give in seconds
  return (
    [padTo2Digits(hours),
     padTo2Digits(minutes),
     padTo2Digits(Math.ceil(seconds))].join(':')
  )
}
