import React from "react";
import {Box, Theme, Typography,} from "@mui/material";
import {
    createStyles,
    makeStyles,
} from "@mui/styles";
import WarningIconRounded from "@mui/icons-material/WarningRounded";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      zIndex: 20000,
      flex: 1,
      display: "flex",
      flexDirection: "column",
      overflow: "auto",
      paddingBottom: 20,
      position: "fixed",
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      padding: 20,
      alignItems: "center",
      justifyContent: "center",
      backgroundColor: "#ffffff",
    },
    box: {
      display: "flex",
      flexDirection: "column",
      color: "#5f2120",
      backgroundColor: "#fdeded",
      borderRadius: 10,
      padding: 20,
    },
    column: {
      display: "flex",
      flexDirection: "column",
    },
    row: {
      display: "flex",
      flexDirection: "row",
    },
    logo: {
      width: 200,
    },
    icon: {
      color: theme.palette.secondary.main,
      marginRight: 10,
    },
    title: {
      fontWeight: "bold",
    },
  })
);

export const Error = () => {
  const classes = useStyles();

  return (
    <Box className={classes.container}>
      <Box className={classes.box}>
        <Box className={classes.row}>
          <WarningIconRounded className={classes.icon} />
          <Box className={classes.column}>
            <Typography align="left" className={classes.title}>
              Error
            </Typography>
            <Typography align="left">The provided URL is not valid.</Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
