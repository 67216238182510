import React, {useEffect, useState} from "react";
import {
    Box,
    Button,
    Dialog,
    IconButton,
    Slide,
    Theme,
    Typography,
    TextField,
    Divider
} from "@mui/material";
import {
    createStyles,
    makeStyles,
} from "@mui/styles";
import {formatDate} from "../../utils/dateFunctions";
import {formatTime} from "../../utils/dateFunctions";
import {BookedSlot} from "../../models/models";
import {v4 as uuid} from "uuid";
import { CloseRounded } from "@mui/icons-material";
import {useSnackbar} from "notistack";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
    container: {
      flex: 1,
      display: "flex",
      flexDirection: "column",
      marginLeft: 10,
      marginRight: 10,
      overflow: "auto",
    },
    formContainer: {
      display: "flex",
      flexDirection: "column",
      alignItems: "left",
      marginLeft: 10,
      marginRight: 10,
    },
    textField: {
      borderRadius: 10,
      backgroundColor: theme.palette.background.default,
    },
    informationContainer: {
      marginLeft: 10,
      marginRight: 10,
      marginBottom: 10,
      marginTop: 10,
    },
    row: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-between",
    },
    })
);


export const SubmitRoomBookingDialog: React.FC<{
  date: Date,
  customer: Customer,
  room: Room,
  bookingSlots: BookingSlots[],
  openDialog: Boolean,
  closeDialog: () => void,  //send it back to parent on close
  saveRoomBooking: (bookedSlots: BookedSlot[]) => void,

}> = ({
  date,
  customer,
  room,
  bookingSlots,
  openDialog,
  closeDialog,
  saveRoomBooking
}) => {

  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const initBookedSlots = (bookingSlots: BookingSlots[]) => {
    //summarize booking slots to booked slots
    let newlyBookedSlots = [];
    bookingSlots.filter((thisBookingSlot) => thisBookingSlot.selected === true)
                   .map((thisBookingSlot) => {
                     //check if a booked slot is already existing with new booking slot to be added before
                     const thisBookedSlotIndex1 = newlyBookedSlots.findIndex((thisBookedSlot) =>
                                thisBookingSlot.tstart <= thisBookedSlot.tstart &&
                                thisBookingSlot.tend >= thisBookedSlot.tstart);
                     //check if a booked slot is already existing with new booking slot to be added after
                     const thisBookedSlotIndex2 = newlyBookedSlots.findIndex((thisBookedSlot) =>
                                thisBookingSlot.tstart <= thisBookedSlot.tend &&
                                thisBookingSlot.tend >= thisBookedSlot.tend);
                      if(thisBookedSlotIndex1 === -1 && thisBookedSlotIndex2 === -1){
                        //create new booked slot
                        const newlyBookedSlot = new BookedSlot(uuid(),
                                                               mutableSubject,
                                                               customer,
                                                               room,
                                                               date,
                                                               thisBookingSlot.tstart,
                                                               thisBookingSlot.tend);
                          newlyBookedSlots.push(newlyBookedSlot);
                       } else if(thisBookedSlotIndex1 !== -1){
                         //adapt start time
                         newlyBookedSlots[thisBookedSlotIndex1].tstart = thisBookingSlot.tstart;
                       } else if(thisBookedSlotIndex2 !== -1){
                         //adapt end time
                         newlyBookedSlots[thisBookedSlotIndex2].tend = thisBookingSlot.tend;
                       }
                       return thisBookingSlot;
                   });
    return newlyBookedSlots;
  }

  useEffect(() => {
    setBookedSlots(initBookedSlots(bookingSlots));
  }, [bookingSlots]);


  const [mutableSubject, setMutableSubject] = useState<string>("");
  const [bookedSlots, setBookedSlots] = useState<BookedSlot[]>([]);

  const currentCosts = bookingSlots.filter(thisBookingSlot => thisBookingSlot.selected === true)
                                   .reduce((sum, thisBookingSlot) => sum + 0.5*room.costPerHour, 0);

  return (

    <Dialog
          open={openDialog}
          onClose={closeDialog}
          fullWidth={true}
          maxWidth={"lg"}
          TransitionComponent={Slide}
          TransitionProps={{direction: "up"} as any}
    >

    <Box className={classes.container}>
      <Box className={classes.row}>
        <Typography
          variant="h6"
          color="textPrimary"
          style={{ marginTop: 20, marginBottom: 20 }}
        >
          Book a Room
        </Typography>

        <IconButton
            edge="start"
            color="inherit"
            aria-label="close"
            onClick={closeDialog}
        >
           <CloseRounded />
        </IconButton>
      </Box>

      <Box style={{ height: 40 }} />

       <Divider/>

        <Box className={classes.informationContainer}>
            <Typography
              color="textPrimary"
              align="left"
              style={{ fontWeight: "bold" }}
            >
              Information
            </Typography>
            <Typography color="textSecondary" align="left">
               Customer: {customer.name}
            </Typography>
            <Typography color="textSecondary" align="left">
               Date: {formatDate(date)}
            </Typography>
            <Typography color="textSecondary" align="left">
               Room: {room.name}
            </Typography>
        </Box>

        <Box className={classes.informationContainer}>
            <Typography
              color="textPrimary"
              align="left"
              style={{ fontWeight: "bold" }}
            >
              Credit Balance
            </Typography>
            <Typography color="textSecondary" align="left">
               Available Credits: {customer.creditBalance}
            </Typography>
            <Typography color="textSecondary" align="left">
               Total Costs: {currentCosts}
            </Typography>
            <Typography color="textSecondary" align="left">
               Credits left after transaction: {customer.creditBalance - currentCosts}
            </Typography>
        </Box>

     <Divider style={{marginBottom: 10}}/>

        <Box className={classes.informationContainer}>
            <Typography
              color="textPrimary"
              align="left"
              style={{ fontWeight: "bold" }}
            >
              Slots
            </Typography>
            {bookedSlots.map((bookedSlot) => {
              return(
                <Typography
                 key={uuid()}
                 color="textSecondary" align="left"
                >
                   Time: {formatTime(bookedSlot.tstart)} - {formatTime(bookedSlot.tend)}
            </Typography>
              )

            })}
        </Box>

     <Divider style={{marginBottom: 10}}/>


      <Box className={classes.formContainer}>
      <Box className={classes.textField}>
          <TextField
            label={"Subject (max. 255)"}
            variant="filled"
            color="primary"
            fullWidth
            required
            InputProps={{
              disableUnderline: true,
            }}
            inputProps={{
              maxLength: 255,
            }}
            value={mutableSubject}
            onChange={(event) =>
              {
                setMutableSubject(event.target.value);
                setBookedSlots(bookedSlots.map((thisBookedSlot) => {
                                         thisBookedSlot.subject=event.target.value;
                                         return thisBookedSlot}));
            }
          }
        />
        </Box>
        <Box style={{ height: 10,
                      marginBottom: 10,
                      marginTop: 0}} />

          <Button
              variant="contained"
              color="primary"
              fullWidth
              onClick={() => {
                  if(!mutableSubject.trim().length){
                     enqueueSnackbar("Please provide a subject.", {
                          variant: "error",
                      });
                  } else {
                    saveRoomBooking(bookedSlots);
                    closeDialog();
                  }
              }
            }
            >
             Submit
            </Button>
        <Box style={{ height: 10,
                      marginBottom: 10,
                      marginTop: 0}} />
      </Box>


    </Box>
      </Dialog>
);
};
