import React, {useState} from "react";
import {
    Slide,
    Dialog,
    IconButton,
    Toolbar,
    Button,
    Box,
    Typography
} from "@mui/material";
import {
    createStyles,
    makeStyles,
} from "@mui/styles";
import {CloseRounded, NavigateNext, NavigateBefore} from "@mui/icons-material";
import HelpIcon from "@mui/icons-material/Help";
import {Room, BookedSlot, BookingSlot, Customer} from "../../models/models";
import {CalendarScreen} from "./CalendarScreen";
import {formatDate} from '../../utils/dateFunctions'
import {v4 as uuid} from "uuid";
import {CalendarDialog} from "./CalendarDialog";
import {MovieDialog} from "./MovieDialog";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dialog: {
      backgroundColor: theme.palette.background.default,
    },
    toolBar: {
      border: `1px solid ${theme.palette.background.default}`,
    },
    rowContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      backgroundColor: theme.palette.background.default,
    },
    arrowContainer:{
      justifyContent: "center",
      alignItems: "center",
    },
  })
);


//Dialog screen for a certain think tank table
export const RoomCalendarScreen: React.FC<{
  customer: Customer,
  room: Room,
  bookedSlots: BookedSlot[],
  closeDialog: () => void,  //send it back to parent on close
  saveRoomBooking: (bookedSlots: BookedSlot[]) => void,
  deleteRoomBooking: (bookedSlot: BookedSlot) => void
}> = ({
  customer,
  room,
  bookedSlots,
  closeDialog,
  saveRoomBooking,
  deleteRoomBooking
}) => {

  const classes = useStyles();

  const closeDialogLocal = () => {
    closeDialog();
  }

  const addDay = () => {
      let newDate = new Date(selectedDate);
      newDate.setDate(newDate.getDate()+1);
      setSelectedDate(newDate);
      setBookingSlotsThisDate(initBookingSlots(newDate));
  };
  const subtractDay = () => {
      let newDate = new Date(selectedDate);
      newDate.setDate(newDate.getDate()-1);
      setSelectedDate(newDate);
      setBookingSlotsThisDate(initBookingSlots(newDate));
  };

  const initBookingSlots = ((date: Date) => {
  //all possible booking slots in steps of 30 minutes
    const nd = (23.5-0.0)/0.5 + 1
    const bookingSlots = [];
    for(let i=0; i<nd; i++){
        const tstart = new Date(date.getFullYear(),
                                date.getMonth(),
                                date.getDate(),
                                0);
        tstart.setTime(tstart.getTime() + i*30*60*1000) //add always 30 minutes (in miliseconds)

        const tend = new Date(date.getFullYear(),
                          date.getMonth(),
                          date.getDate(),
                          0,
                          30);
        tend.setTime(tend.getTime() + i*30*60*1000)

        //check if there is a booked slot for this booking slot
        const bookedSlotsThisDate = bookedSlots.filter((bookedSlot) => bookedSlot.date.getDate() === date.getDate())
        const bookedSlot = bookedSlotsThisDate.find((thisBookedSlot) =>
              (thisBookedSlot.tstart <= tstart &&
               thisBookedSlot.tend >= tend)
            );


        const bookingSlot = new BookingSlot(uuid(),
                                            bookedSlot || null,
                                            tstart,
                                            tend,
                                            false)
        bookingSlots.push(bookingSlot);
    }
    return bookingSlots;
  });

  const [selectedDate, setSelectedDate] = useState<Date>(new Date());
  const [openCalendarDialog, setOpenCalendarDialog] = useState<Boolean>(false);
  const [openMovieDialog, setOpenMovieDialog] = useState<Boolean>(false);
  const [bookingSlotsThisDate, setBookingSlotsThisDate] = useState<BookingSlot[]>(() => initBookingSlots(new Date()));

  const pickDateFromCalendar = (newDate: Date) => {
    setSelectedDate(newDate);
    setBookingSlotsThisDate(initBookingSlots(newDate));
    setOpenCalendarDialog(false);
  }

  const closeMovieDialog = () => {
    setOpenMovieDialog(false);
  }

  return (
  <Dialog
      fullScreen
      open={Boolean(room)}
      onClose={closeDialogLocal}
      TransitionComponent={Slide}
      TransitionProps={{direction: "up"} as any}
      PaperProps={{
        className: classes.dialog
      }}
  >

    <CalendarDialog
        openDialog={openCalendarDialog}
        selectedDate={selectedDate}
        setSelectedDate={pickDateFromCalendar}
    />

    <MovieDialog
        openDialog={openMovieDialog}
        closeDialog={closeMovieDialog}
    />

    <Toolbar className={classes.toolBar}
             style={{padding: 10,
                     backgroundSize: 'cover',
                     backgroundPosition: 'left 20%',
                     backgroundImage: `url('${room.backgroundImgUrl}')`}}
    >
      <Typography style={{ marginLeft: 2, flex: 1 }} variant="h6" component="div">
          {room.name}
      </Typography>

{/*
      <IconButton
          edge="start"
          color="inherit"
          onClick={() => setOpenMovieDialog(true)}
      >
        <HelpIcon />
      </IconButton>
*/
}

      <IconButton
          edge="start"
          color="inherit"
          onClick={closeDialogLocal}
          aria-label="close"
      >
        <CloseRounded />
      </IconButton>
    </Toolbar>

    <Box className={classes.rowContainer}>
       <Box className={classes.arrowContainer}>
         <IconButton
            edge="start"
            color="inherit"
            onClick={(e) => {
              subtractDay();
            }}
            aria-label="close"
         >
           <NavigateBefore />
         </IconButton>
       </Box>

       <Box>
          <Button
           variant="contained"
           disableElevation={true}
           fullWidth
            style={{
              backgroundSize: 'cover',
              color: 'inherit',
              border: '0px solid',
              backgroundColor: 'transparent',
              paddingTop: 10

            }}
            onClick={(e) => {
              setOpenCalendarDialog(true);
            }}
            >
          <Typography variant="h6">
             {formatDate(selectedDate)}
          </Typography>
          </Button>
       </Box>

       <Box className={classes.arrowContainer}>
          <IconButton
             edge="start"
             color="inherit"
             onClick={() => {
               addDay();
             }}
             aria-label="close"
          >
             <NavigateNext />
          </IconButton>
       </Box>
    </Box>

    <CalendarScreen
       customer = {customer}
       room = {room}
       date = {selectedDate}
       bookingSlots = {bookingSlotsThisDate}
       saveRoomBooking = {saveRoomBooking}
       deleteRoomBooking = {deleteRoomBooking}
    />

  </Dialog>
  );
};
