import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {AppThunk, RootState} from "../../app/store";
import {BookedSlot,
        BookedSlotFactory,
        Customer,
        CustomerFactory} from "../../models/models";
import Config from "../../config";

interface BookedSlotState {
  isFetching: boolean;
  bookedSlots: BookedSlot[];
  error: null | string;
  errorUpdate: null | string;
}

const initialState: BookedSlotState = {
  isFetching: false,
  bookedSlots: [],
  error: null,
  errorUpdate: null
};

export const bookedSlotSlice = createSlice({
  name: "bookedSlots",
  initialState,
  reducers: {
    storeFetching: (state, action: PayloadAction<boolean>) => {
      state.isFetching = action.payload;
    },
    storeBookedSlots: (state, action: PayloadAction<BookedSlot[]>) => {
      state.bookedSlots = action.payload.map((bookedSlot) => bookedSlot);
    },
    storeError: (state, action: PayloadAction<string | null>) => {
      state.error = action.payload;
    },
    storeErrorUpdate: (state, action: PayloadAction<string | null>) => {
      state.errorUpdate = action.payload;
    },
    purgeStore: (state) => {
      state.bookedSlots = initialState.bookedSlots;
      state.isFetching = false;
      state.error = null;
      state.errorUpdate = null;
    },
  },
});

export const { storeBookedSlots, storeFetching, storeError, storeErrorUpdate, purgeStore } = bookedSlotSlice.actions;

export const retrieveBookedSlots =
  (authToken: string,
   customer: Customer): AppThunk =>
  async (dispatch) => {

    try {
      const headers: Headers = new Headers();

      headers.append("Accept", "application/json");
      headers.append("Content-Type", "application/json");
      headers.append("Authorization", `Bearer ${authToken}`);

      let url = `${Config.getInstance().getCoordinationServiceURL()}/api/retrieve_booked_slots`;

      dispatch(purgeStore());
      dispatch(storeFetching(true));

      const response = await fetch(url, {
        method: "POST",
        headers: headers,
        body: JSON.stringify(new CustomerFactory().toJSON(customer))
      })
      if (!response.ok) {
        dispatch(storeError("Error when fetching booked slots"));
        dispatch(storeFetching(false));
        return;
      } else {
        dispatch(storeError(null));
      }

      const data = await response.json();

      if (!data || !Array.isArray(data)) {
          dispatch(storeError("Error when fetching booked slots"));
          dispatch(storeFetching(false));
          return;
      }

      dispatch(
            storeBookedSlots(data.map((bookedSlotJSON) =>
                new BookedSlotFactory().fromJSON(bookedSlotJSON)
              )
            )
          );

      dispatch(storeFetching(false));

    } catch (error) {
      dispatch(storeError(error));
      dispatch(storeFetching(false));
      console.error(error);
    }

  };

  export const updateBookedSlots =
    (
      authToken: string,
      bookedSlots: BookedSlot[],
      customer: Customer,
    ): AppThunk =>
    async (dispatch) => {
      try {
        const headers: Headers = new Headers();

        headers.append("Accept", "application/json");
        headers.append("Content-Type", "application/json");
        headers.append("Authorization", `Bearer ${authToken}`);


        let url = `${Config.getInstance().getCoordinationServiceURL()}/api/update_booked_slots`;

        const bookedSlotFactory = new BookedSlotFactory();

        dispatch(storeFetching(true));
        const response = await fetch(url, {
          method: "PUT",
          headers: headers,
          body: JSON.stringify(
            {customer: new CustomerFactory().toJSON(customer),
             bookedSlots: bookedSlots.map((bookedSlot) => {
                  const bookedSlotJSON = bookedSlotFactory.toJSON(bookedSlot);
                  return bookedSlotJSON;
                }
              )
            }
          ),
        })

        const data = await response.json();
        if (!data || !Array.isArray(data)) {
          let errorMessage = 'Something went wrong. Please contact the system administrator.';
          if( data.error){
            errorMessage = data.error;
          }

          dispatch(storeErrorUpdate(errorMessage));
          dispatch(storeFetching(false));
          return errorMessage;
        }
        dispatch(purgeStore());
        dispatch(
            storeBookedSlots(data.map((bookedSlotJSON) =>
                new BookedSlotFactory().fromJSON(bookedSlotJSON)
              )
            )
          );

        dispatch(storeFetching(false));
        return 'success';

    } catch (error) {
      dispatch(storeErrorUpdate(error));
      dispatch(storeFetching(false));
      console.error(error);
      return 'Something went wrong. Please contact the system administrator.';
    }
  };

  export const deleteBookedSlot =
    (
      authToken: string,
      bookedSlot: BookedSlot,
      customer: Customer,
    ): AppThunk =>
    async (dispatch) => {
      try {
        const headers: Headers = new Headers();

        headers.append("Accept", "application/json");
        headers.append("Content-Type", "application/json");
        headers.append("Authorization", `Bearer ${authToken}`);


        let url = `${Config.getInstance().getCoordinationServiceURL()}/api/delete_booked_slot`;

        const bookedSlotFactory = new BookedSlotFactory();

        dispatch(storeFetching(true));
        const response = await fetch(url, {
          method: "PUT",
          headers: headers,
          body: JSON.stringify(
            {customer: new CustomerFactory().toJSON(customer),
             bookedSlot: bookedSlotFactory.toJSON(bookedSlot)
            }
          ),
        })

        const data = await response.json();
        if (!data || !Array.isArray(data)) {
          let errorMessage = 'Something went wrong. Please contact the system administrator.';
          if( data.error){
            errorMessage = data.error;
          }

          dispatch(storeErrorUpdate(errorMessage));
          dispatch(storeFetching(false));
          return errorMessage;
        }
        dispatch(purgeStore());
        dispatch(
            storeBookedSlots(data.map((bookedSlotJSON) =>
                new BookedSlotFactory().fromJSON(bookedSlotJSON)
              )
            )
          );

        dispatch(storeFetching(false));
        return 'success';

    } catch (error) {
      dispatch(storeErrorUpdate(error));
      dispatch(storeFetching(false));
      console.error(error);
      return 'Something went wrong. Please contact the system administrator.';
    }
  };


export const selectIsFetching = (state: RootState) =>
  state.bookedSlotStore.isFetching;

export const selectError = (state: RootState) => {
  return {'error': state.bookedSlotStore.error,
          'errorUpdate': state.bookedSlotStore.errorUpdate};
}

export const selectBookedSlots = (state: RootState) =>{
    return state.bookedSlotStore.bookedSlots;
}

export default bookedSlotSlice.reducer;
