import React, {useEffect, useState, useRef} from "react";
import {
    Box,
    Dialog,
    IconButton,
    Slide,
    Theme,
    Typography,
} from "@mui/material";
import {
    createStyles,
    makeStyles,
} from "@mui/styles";
import { CloseRounded } from "@mui/icons-material";
import {formatDuration} from '../../utils/dateFunctions'


const useStyles = makeStyles((theme: Theme) =>
    createStyles({
    container: {
      flex: 1,
      display: "flex",
      flexDirection: "column",
      paddingBottom: 20,
      paddingLeft: 20,
      paddingRight: 20,
      backgroundColor: theme.palette.background.paper,
      overflow: "auto",
    },
    row: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between"
    },
    movieContainer:{
      display: "flex",
      justifyContent: "center",
      animationFillMode: "forwards",
    }
    })
);

export const MovieDialog: React.FC<{
  openDialog: boolean,
  closeDialog: () => void,  //send it back to parent on close
}> = ({
  openDialog,
  closeDialog
}) => {

  const classes = useStyles();

  const videoElement = useRef(null);

  const [videoDuration, setVideoDuration] = useState<string>('');

  const handleLoadedMetadata = () => {
    setVideoDuration(formatDuration(videoElement.current.duration));
  }

  const closeThisDialog = () => {
    closeDialog()
  }

  return (

    <Dialog
          open={openDialog}
          onClose={closeThisDialog}
          fullWidth={true}
          maxWidth={"lg"}
          TransitionComponent={Slide}
          TransitionProps={{direction: "up"} as any}
    >

    <Box className={classes.container}>
      <Box className={classes.row}>
        <Typography
          variant="h6"
          color="textPrimary"
          style={{ marginTop: 20, marginBottom: 20 }}
        >
          Explanation Movie -- {videoDuration}
        </Typography>

        <IconButton
            edge="start"
            color="inherit"
            aria-label="close"
            onClick={closeDialog}
        >
           <CloseRounded />
        </IconButton>

      </Box>
      <Box className={classes.movieContainer}>
      <video src="/movies/explanation_rbm_app.webm"
             ref={videoElement}
             onLoadedMetadata={handleLoadedMetadata}
             type="video/webm"
             width="80%"
             autoPlay
             muted
        />
      </Box>

    </Box>

    </Dialog>


);
};
