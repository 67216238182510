import {
    Box,
    Dialog,
    IconButton,
    Slide,
    Theme,
    Typography,
} from "@mui/material";
import {
    createStyles,
    makeStyles,
} from "@mui/styles";
import { CloseRounded } from "@mui/icons-material";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import dayjs from 'dayjs';


const useStyles = makeStyles((theme: Theme) =>
    createStyles({
    container: {
      flex: 1,
      display: "flex",
      flexDirection: "column",
      paddingBottom: 20,
      paddingLeft: 20,
      paddingRight: 20,
      backgroundColor: theme.palette.background.paper,
      overflow: "auto",
    },
    row: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between"
    },
    })
);

export const CalendarDialog: React.FC<{
  openDialog: boolean,
  selectedDate: Date,
  setSelectedDate: (newDate: Date) => void,  //send it back to parent on close
}> = ({
  openDialog,
  selectedDate,
  setSelectedDate,
}) => {

  const classes = useStyles();

  const closeDialog = () => {
    setSelectedDate(new Date(selectedDate));
  }

  return (

    <Dialog
          open={openDialog}
          onClose={closeDialog}
          fullWidth={true}
          maxWidth={"lg"}
          TransitionComponent={Slide}
          TransitionProps={{direction: "up"} as any}
    >

    <Box className={classes.container}>
      <Box className={classes.row}>

        <Typography
          variant="h6"
          color="textPrimary"
          style={{ marginTop: 20, marginBottom: 20 }}
        >
          Select Date
        </Typography>

        <IconButton
            edge="start"
            color="inherit"
            aria-label="close"
            onClick={closeDialog}
        >
           <CloseRounded />
        </IconButton>

      </Box>

      <LocalizationProvider dateAdapter={AdapterDayjs}>
         <DateCalendar
            value={dayjs(selectedDate)}
            onChange={(newCalendarValue) => setSelectedDate(new Date(newCalendarValue))}
            views={['day']}
         />
      </LocalizationProvider>

    </Box>

    </Dialog>


);
};
