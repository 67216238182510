import React, {useEffect, useState} from "react";
import {
    Theme,
    Typography,
    Box
} from "@mui/material";
import {
    createStyles,
    makeStyles,
} from "@mui/styles";
import {useDispatch, useSelector} from "react-redux";
import {selectAuthenticationToken,
        selectCustomer} from "../authentication/authenticationSlice";
import {retrieveRooms, selectRooms, selectIsFetching as selectIsFetchingRooms} from "./roomSlice";
import {retrieveBookedSlots,
        selectBookedSlots,
        selectIsFetching as selectIsFetchingBookedSlots} from "./bookedSlotSlice";
//import {Employee, EmployeeFactory,} from "../../models/models";
import {RoomScreen} from "./RoomScreen";
import LinearProgress from "@mui/material/LinearProgress";
import {useSnackbar} from "notistack";
import {getCurrentCostsB} from "../../utils/costFunctions";
import {updateBookedSlots,
        deleteBookedSlot,} from "./bookedSlotSlice";


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      flex: 1,
      display: "flex",
      flexDirection: "column",
      paddingBottom: 20,
      paddingLeft: 20,
      paddingRight: 20,
      backgroundColor: theme.palette.background.default,
      overflow: "auto",
    },
    scrollContainer: {
      paddingBottom: 20,
      backgroundColour: theme.palette.background.paper
    },
    name: {
      fontWeight: "bold",
    },
    description: {
      textOverflow: "ellipsis",
      overflow: "hidden",
      whiteSpace: "pre",
      minWidth: 0,
    },
    icon: {
      color: theme.palette.text.primary,
    },
    rowContainer: {
      flex: 1,
      display: "flex",
      alignItems: "center",
      flexDirection: "column",
//      backgroundColor: theme.palette.background.paper,
      paddingLeft: 10,
      paddingRight: 10,
      borderRadius: 10,

    },
    dialog: {
      backgroundColor: theme.palette.background.default,
    },
    toolBar: {
      border: `1px solid ${theme.palette.background.paper}`,
    },
  })
);

export const Rooms = () => {
  const classes = useStyles();

  const dispatch = useDispatch();

  const { enqueueSnackbar } = useSnackbar();

  const authToken = useSelector(selectAuthenticationToken);
  const customer = useSelector(selectCustomer);
  const rooms = useSelector(selectRooms);
  const bookedSlots = useSelector(selectBookedSlots);
  const isFetchingBookedSlots = useSelector(selectIsFetchingBookedSlots);
  const isFetchingRooms = useSelector(selectIsFetchingRooms);

  const [thisCustomer, setThisCustomer] = useState<Customer>(customer);

  useEffect(() => {
    if (authToken && customer) {
      dispatch(retrieveRooms(authToken, customer));
      dispatch(retrieveBookedSlots(authToken, customer));
    }
    if (customer) {
      setThisCustomer(customer);
    }
  }, [customer]); // eslint-disable-line react-hooks/exhaustive-deps

  const saveRoomBooking = async(bookedSlots: BookedSlot[]) => {
    const message = await dispatch(updateBookedSlots(authToken, bookedSlots, thisCustomer));
    if(message === 'success'){
       //update customer
       const currentCosts = getCurrentCostsB(bookedSlots);
       setThisCustomer({...thisCustomer,
                        creditBalance: thisCustomer.creditBalance - currentCosts})
       enqueueSnackbar("The meeting slot(s) have been submitted successfully.", {
               variant: "success",
          });
    } else{
       enqueueSnackbar(message, {
               variant: "error",
          });
    }
  };

  const deleteRoomBooking = async(bookedSlot: BookedSlot) => {
    const message = await dispatch(deleteBookedSlot(authToken, bookedSlot, thisCustomer));
    if(message === 'success'){
       //update customer
       const currentCosts = getCurrentCostsB([bookedSlot]);
       setThisCustomer({...thisCustomer,
                         creditBalance: thisCustomer.creditBalance + currentCosts})
       enqueueSnackbar("The meeting has been deleted successfully.", {
               variant: "success",
          });
    } else{
       enqueueSnackbar(message, {
               variant: "error",
          });
    }
  }

  const isLoading = isFetchingBookedSlots || isFetchingRooms;

  return (
    <Box className={classes.container}>

      {isLoading && <LinearProgress color="primary" />}

      {!isLoading && thisCustomer !== null &&
        <Box className={classes.scrollContainer}>

          <Typography variant="h6" color="textPrimary" style={{ marginTop: 20 }}>
            Customer: {thisCustomer?.name}
          </Typography>
          <Typography color="textSecondary" style={{ marginBottom: 20 }}>
            Please select a room for which you want to book (available credits: {thisCustomer?.creditBalance})
          </Typography>

          <Box
            className={classes.rowContainer}
          >
            {rooms.map((room) => <RoomScreen
                                    key = {room.id}
                                    customer = {thisCustomer}
                                    room = {room}
                                    bookedSlots = {bookedSlots.filter((thisBookedSlot) =>
                                                          thisBookedSlot.room.id === room.id
                                                    )}
                                    saveRoomBooking = {saveRoomBooking}
                                    deleteRoomBooking = {deleteRoomBooking}
                                 />)}
            </Box>

          </Box>
        }
        </Box>
    );
};
